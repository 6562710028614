import React from "react";
import "./Footer.css";
import Wave from "../../img/wave.png";
import Insta from "@iconscout/react-unicons/icons/uil-instagram";
import Facebook from "@iconscout/react-unicons/icons/uil-facebook";
import Gitub from "@iconscout/react-unicons/icons/uil-github";
import whatsapp from '../../img/whatsappwhite.png'

const Footer = () => {
  const phoneNumber = "+919996230894"; // Replace with your client's WhatsApp number
  const message = "Hi Travel Shoppie";

  const openWhatsApp = () => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, "_blank");
  };
  return (
    <div className="footer">
      <img src={Wave} alt="" style={{ width: "100%", height: '35rem' }} />
      <div className="f-content">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span>Email -</span>

          <a href="mailto:travelshoppie@gmail.com" style={{ color: 'white' }}>
            <span>travelshoppie@gmail.com</span>
          </a>
          <a href="mailto:Info@travelshoppie.com" style={{ color: 'white' }}>
            <span>Info@travelshoppie.com</span>
          </a>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>For booking contact travel shoppie</span>
          <a href="tel:+919996230894" style={{ color: 'white' }}>
            <span>+91 9996230894</span>
          </a>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>• Office address -</span>
          <span>Office No. J-19 Shop No. 1 Ground Floor</span>
          <span>Rajouri Garden, New Delhi -110027</span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>


          <span>• Panipat Office address :-
          </span>
          <span>Suresh Complex ,
            Bishan Sawroop</span>
          <span>Colony, Near Deep
            Hotel, Panipat -
            132103</span>
        </div>
        <div className="f-icons">
          <a href="https://www.instagram.com/travelshoppie" target="_blank" rel="noopener noreferrer">
            <Insta color="white" size={"3rem"} />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61563380602422" target="_blank" rel="noopener noreferrer">
            <Facebook color="white" size={"3rem"} />
          </a>
          <img onClick={openWhatsApp} src={whatsapp} alt="image" style={{ height: '50px', width: '50px', cursor: 'pointer' }} />

          {/* <Gitub color="white" size={"3rem"} /> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;

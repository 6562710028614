import React, { useState, useContext } from "react";
import styles from "./Recommendation.module.css";
import Destination1 from "../../img/Destination1.png";
import Destination2 from "../../img/Destination2.png";
import Destination3 from "../../img/Destination3.png";
import Destination4 from "../../img/Destination4.png";
import Destination5 from "../../img/Destination5.png";
import Destination6 from "../../img/Destination6.png";
import malaysia from "../../img/malaysia.jpg";
import indonesia from "../../img/indonesia.jpg";
import vietnam from "../../img/vietnam.jpg";
import baku from "../../img/baku.jpg";
import maldives from "../../img/maldives.jpg";
import srilanka from "../../img/srilanka.jpg";
import Mauritius from "../../img/Mauritius.jpg";
import dubai from "../../img/dubai.jpg";
import kazakstan from "../../img/kazakstan.jpg";
import jordan from "../../img/jordan.jpg";
import cambodia from "../../img/cambodia.jpg";
import bali from "../../img/bali.jpg";
import info1 from "../../img/info1.png";
import info2 from "../../img/info2.png";
import info3 from "../../img/info3.png";
import { themeContext } from "../../Context";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function Recommendation() {
  const [active, setActive] = useState();
  const [activeItem, setActiveItem] = useState('weekendbreak');
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  const [loading, setLoading] = useState(false);

  const data = {
    "weekendbreak": [
      {
        image: Destination1,
        title: "Singapore",
        subTitle: "Singapore, officially the Republic of Singapore, is a global financial hub with a rich multicultural heritage.",
        cost: "38,800",
        duration: "Approx 2 night trip",
      },
      {
        image: Destination2,
        title: "Thailand",
        subTitle: "Thailand is known for its stunning beaches, vibrant culture, and delicious cuisine.",
        cost: "54,200",
        duration: "Approx 2 night trip",
      },
      {
        image: Destination3,
        title: "Paris",
        subTitle: "Paris, France's capital, is renowned for its art, fashion, and iconic landmarks such as the Eiffel Tower.",
        cost: "45,500",
        duration: "Approx 2 night trip",
      },
      {
        image: Destination4,
        title: "New Zealand",
        subTitle: "New Zealand is famous for its breathtaking landscapes, adventure sports, and rich Maori culture.",
        cost: "24,100",
        duration: "Approx 1 night trip",
      },
      {
        image: Destination5,
        title: "Bora Bora",
        subTitle: "Bora Bora is a luxurious island destination known for its crystal-clear waters and overwater bungalows.",
        cost: "95,400",
        duration: "Approx 2 night 2 day trip",
      },
      {
        image: Destination6,
        title: "London",
        subTitle: "London, the capital of England, is a city rich in history with attractions like the British Museum and Buckingham Palace.",
        cost: "38,800",
        duration: "Approx 3 night 2 day trip",
      },
    ],
    "packageHoliday": [
      {
        image: vietnam,
        title: "Vietnam",
        subTitle: "Vietnam offers a vibrant culture, delicious street food, and beautiful landscapes ranging from beaches to mountains.",
        cost: "38,800",
        duration: "Approx 2 night trip",
      },
      {
        image: dubai,
        title: "Dubai",
        subTitle: "Dubai is known for its modern architecture, luxurious shopping, and vibrant nightlife.",
        cost: "54,200",
        duration: "Approx 2 night trip",
      },
      {
        image: bali,
        title: "Bali",
        subTitle: "Bali is a tropical paradise famous for its beautiful beaches, rich cultural traditions, and lush landscapes.",
        cost: "45,500",
        duration: "Approx 2 night trip",
      },
      {
        image: Mauritius,
        title: "Mauritius",
        subTitle: "Mauritius is an island nation known for its pristine beaches, turquoise waters, and rich cultural heritage.",
        cost: "24,100",
        duration: "Approx 1 night trip",
      },
      {
        image: baku,
        title: "Baku",
        subTitle: "Baku, the capital of Azerbaijan, is known for its modern skyline, historical sites, and vibrant culture.",
        cost: "95,400",
        duration: "Approx 2 night 2 day trip",
      },
      {
        image: srilanka,
        title: "Sri Lanka",
        subTitle: "Sri Lanka is a beautiful island nation known for its diverse landscapes, ancient temples, and vibrant wildlife.",
        cost: "38,800",
        duration: "Approx 3 night 2 day trip",
      },
    ],
    "groupTour": [
      {
        image: indonesia,
        title: "Indonesia",
        subTitle: "Indonesia is an archipelago with stunning beaches, rich culture, and diverse wildlife.",
        cost: "38,800",
        duration: "Approx 2 night trip",
      },
      {
        image: cambodia,
        title: "Cambodia",
        subTitle: "Cambodia is known for its rich history, including the magnificent Angkor Wat temple complex.",
        cost: "54,200",
        duration: "Approx 2 night trip",
      },
      {
        image: maldives,
        title: "Maldives",
        subTitle: "The Maldives is a tropical paradise with crystal-clear waters, coral reefs, and luxury resorts.",
        cost: "45,500",
        duration: "Approx 2 night trip",
      },
      {
        image: malaysia,
        title: "Malaysia",
        subTitle: "Malaysia offers a mix of modern cities, tropical islands, and rich cultural heritage.",
        cost: "24,100",
        duration: "Approx 1 night trip",
      },
      {
        image: kazakstan,
        title: "Kazakhstan",
        subTitle: "Kazakhstan boasts diverse landscapes from steppes and deserts to mountains, and a rich cultural history.",
        cost: "95,400",
        duration: "Approx 2 night 2 day trip",
      },
      {
        image: jordan,
        title: "Jordan",
        subTitle: "Jordan is known for its ancient history, including the archaeological site of Petra and the desert landscapes of Wadi Rum.",
        cost: "38,800",
        duration: "Approx 3 night 2 day trip",
      },
    ]
  }
  

  const packages = [{
    name: "The Weekend Break", title: "weekendbreak"
  }, {
    name: "The Package Holiday", title: 'packageHoliday'
  },
  {
    name: "The Group Tour", title: 'groupTour'
  }

    // "Long Term Slow Travel",
  ];

  const handlePackageClick = (index, title) => {
    setActive(index + 1);
    setActiveItem(title);
    setLoading(true); // Set loading to true

    // Simulate data loading delay
    setTimeout(() => {
      setLoading(false); // Set loading to false after 1 second
    }, 2000); // You can adjust this delay
  };

  return (
    <section id="popular" className={styles.recommendation}>
      <div className={styles.title}>
        <h1>Popular Destinations</h1>
        <div className={styles.CategoryBar}>
          <ul>
            {packages.map((pkg, index) => (
              <li
                key={index}
                className={active === index + 1 ? styles.Active : ""}
                onClick={() => handlePackageClick(index, pkg.title)}
              >
                {pkg.name}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className={styles.recommendationBox}>
        {loading ? (
          // Skeleton loader: Repeat this as many times as needed
          Array(6)
            .fill()
            .map((_, index) => (
              <div key={index} className={styles.box}>
                <Skeleton height={150} />
                <Skeleton width={`60%`} height={30} style={{ marginTop: "10px" }} />
                <Skeleton count={2} />
                <Skeleton width={`50%`} height={30} style={{ marginTop: "10px" }} />
              </div>
            ))
        ) : (
          data[activeItem].map((item, index) => (
            <div
              key={index}
              className={styles.box}
              style={{ backgroundColor: darkMode ? "#FFCA4B" : "" ,color:darkMode && "black"}}
            >
              <div className={styles.image}>
                <img src={item.image} alt="image" />
              </div>
              <div style={{ fontWeight: "bold", fontSize: "20px" }}>
                {item.title}
              </div>
              <p>{item.subTitle}</p>

              <div className={styles.price}>
                <div>
                  <img src={info1} alt="image" />
                  <img src={info2} alt="image" />
                  <img src={info3} alt="image" />
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </section>
  );
}

export default Recommendation;
import React, { useState, useContext } from "react";
import "./Testimonial.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { themeContext } from "../../Context";

import { Navigation, Pagination } from "swiper/modules";
// Direct React component imports
// import { Swiper, SwiperSlide } from 'swiper/swiper-react.mjs';
import "swiper/css/pagination";
import profilePic1 from "../../img/noimage.jpg";
import profilePic2 from "../../img/noimage.jpg";
import profilePic3 from "../../img/noimage.jpg";
import profilePic4 from "../../img/noimage.jpg";

const Testimonial = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  const clients = [
    {
      img: profilePic1,
      review:
        "Thank you, TRAVEL SHOPPIE, for planning the perfect trip for us! Everything was so smooth, and we had the best time!",
        name:'Suraj Yadav'
    },
    {
      img: profilePic2,
      review:
        "TRAVEL SHOPPIE, a huge thank you for giving us the best deal with no hidden charges. We’ll surely travel with you again!",
        name:"Kuldeep Jaglan"
    },
    {
      img: profilePic3,
      review:
        "A big thanks to TRAVEL SHOPPIE for making our family vacation memorable.",
        name:'RK Sharma'
    },
    {
      img: profilePic4,
      review:
        "Great Service provide by Travel Shoppie,Thanks.",
        name:'Manjeet Singh'
    },
  ];

  return (
    <div className="t-wrapper" id="testimonial">
      <div className="t-heading"> 
        <span>Clients always enjoy outstanding </span>
        <span>experiences with us </span>
        <span> on every journey...</span>
        <div
          className="blur t-blur1"
          style={{ background: "var(--purple)" }}
        ></div>
        <div className="blur t-blur2" style={{ background: "skyblue" }}></div>
      </div>
      <Swiper
        // install Swiper modules
        modules={[Pagination]}
        slidesPerView={1}
        pagination={{ clickable: true }}
        autoplay={true}
      >
        {clients.map((client, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="testimonial">
                <img src={client.img} alt="" />
                <span  style={{ color: darkMode && "white" }}>{client.review}</span>
                <span   style={{ color: darkMode ? "white" : "black" }}>-{client.name}</span>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Testimonial;

import React, { useContext } from "react";
import "./Intro.css";
import Vector1 from "../../img/vector3.png";
import Vector2 from "../../img/Vector2.png";
import boy from "../../img/travelmain.png";
import travelshoppieImage from "../../img/travel_shopie.png";
import thumbup from "../../img/thumbup.png";
import crown from "../../img/crown.png";
import FloatinDiv from "../FloatingDiv/FloatingDiv";
import Github from "../../img/github.png";
import LinkedIn from "../../img/linkedin.png";
import facebook2 from "../../img/facebook2.png";
import Instagram from "../../img/instagram.png";
import { themeContext } from "../../Context";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import whatsapp from '../../img/whatsapp.png'

const Intro = () => {
  // Transition
  const transition = { duration: 2, type: "spring" };

  // context
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  const phoneNumber = "+919996230894"; // Replace with your client's WhatsApp number
  const message = "Hi Travel Shoppie";

  const openWhatsApp = () => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, "_blank");
  };

  return (
    <div className="Intro" id="Intro">
      {/* left name side */}
      <div className="i-left">
        <div className="i-name">
          {/* yahan change hy darkmode ka */}
          <span style={{ color: darkMode ? "white" : "" }}>Travel Shoppie</span>
          <span style={{ fontSize: "37px" }}>Make your vacation memorable</span>
          <span style={{ color: darkMode ? "white" : "black",fontWeight:'400' }}>
            At Travel Shoppie, we craft personalized travel adventures tailored
            to your dreams. Discover the world with our expert guidance and
            exceptional service.
          </span>
        </div>
        <button onClick={openWhatsApp} className="button i-button">
          Contact us
          <img
            src={whatsapp}
            alt="image"
            style={{ height: "50px", width: "50px", cursor: "pointer" }}
          />
        </button>

        {/* social icons */}
        <div className="i-icons">
          {/* Facebook link */}
          <a href="https://www.facebook.com/profile.php?id=61563380602422" target="_blank" rel="noopener noreferrer">
            <img src={facebook2} alt="Facebook" />
          </a>

          {/* Instagram link */}
          <a href="https://www.instagram.com/travelshoppie" target="_blank" rel="noopener noreferrer">
            <img src={Instagram} alt="Instagram" />
          </a>
        </div>
      </div>
      {/* right image side */}
      <div className="i-right">
        <img src={Vector1} alt="" />
        <img src={Vector2} alt="" />
        <img
          style={{ height: "300px", width: "300px", marginRight: "60px" }}
          src={boy}
          alt=""
        />
        {/* animation */}
        <motion.img
          initial={{ left: "-36%" }}
          whileInView={{ left: "-20%" }}
          transition={transition}
          src={travelshoppieImage}
          style={{ height: "250px", width: "250px" }}
          alt=""
        />

        <motion.div
          initial={{ top: "-4%", left: "74%" }}
          whileInView={{ left: "70%" }}
          transition={transition}
          className="floating-div"
        >
          <FloatinDiv
            img={crown}
            text1="Guaranteed"
            text2="Travel Assistance"
          />
        </motion.div>

        {/* animation */}
        <motion.div
          initial={{ left: "9rem", top: "18rem" }}
          whileInView={{ left: "0rem" }}
          transition={transition}
          className="floating-div"
        >
          {/* floatinDiv mein change hy dark mode ka */}
          <FloatinDiv img={thumbup} text1="Safe & Secured" text2="Travel" />
        </motion.div>

        <div className="blur" style={{ background: "pink" }}></div>
        <div
          className="blur"
          style={{
            background: "#C1F5FF",
            top: "17rem",
            width: "21rem",
            height: "11rem",
            left: "-9rem",
          }}
        ></div>
      </div>
    </div>
  );
};

export default Intro;

import React, { useContext } from "react";
import "./Services.css";
import Card from "../Card/Card";
import HeartEmoji from "../../img/travel3.png";
import Glasses from "../../img/travel1.png";
import Humble from "../../img/travel2.png";
import { themeContext } from "../../Context";
import { motion } from "framer-motion";
import whatsapp from '../../img/whatsapp.png'


const Services = () => {
  // context
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  // transition
  const transition = {
    duration: 1,
    type: "spring",
  };
  const phoneNumber = "+919996230894"; // Replace with your client's WhatsApp number
  const message = "Hi Travel Shoppie";

  const openWhatsApp = () => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, "_blank");
  };
  return (
    <div className="services" id="services">
      {/* left side */}
      <div className="awesome">
        {/* dark mode */}
        <span style={{ color: darkMode ? "white" : "" }}>Our Awesome</span>
        <span>services</span>
        <span style={{ fontSize: "17px", lineHeight: "20px" }}>
          At Travel Shoppie, we turn your travel dreams into unforgettable
          experiences
          <br />
          with expert guides and personalized service. Explore the world with
          us.
        </span>
        <button onClick={openWhatsApp} className="button s-button">
          Contact us
          {/* <img
            src={whatsapp}
            alt="image"
            style={{ height: "50px", width: "50px", cursor: "pointer" }}
          /> */}
        </button>
        <div className="blur s-blur1" style={{ background: "#ABF1FF94" }}></div>
      </div>
      {/* right */}
      <div className="cards">
        {/* first card */}
        <motion.div
          initial={{ left: "20rem" }}
          whileInView={{ left: "14rem" }}
          transition={transition}
        >
          <Card
            emoji={HeartEmoji}
            heading={"TOURISM EXPERIENCE"}
            detail={
              "With the foresight of an innovator and the precision of an expert, we provide outstanding travel experiences."
            }
          />
        </motion.div>
        {/* second card */}
        <motion.div
          initial={{ left: "-11rem", top: "12rem" }}
          whileInView={{ left: "-4rem" }}
          transition={transition}
        >
          <Card
            emoji={Glasses}
            heading={"EXPERT GUIDANCE SERVICE"}
            detail={
              "Professional multilingual guides offering exceptional service and enhancing travel experiences."
            }
          />
        </motion.div>
        {/* 3rd */}
        <motion.div
          initial={{ top: "19rem", left: "25rem" }}
          whileInView={{ left: "12rem" }}
          transition={transition}
        >
          <Card
            emoji={Humble}
            heading={"MORE THAN 100 DESTINATIONS"}
            detail={
              "Discover more than 100 stunning destinations worldwide with TravelShoppie."
            }
            color="rgba(252, 166, 31, 0.45)"
          />
        </motion.div>
        <div
          className="blur s-blur2"
          style={{ background: "var(--purple)" }}
        ></div>
      </div>
    </div>
  );
};

export default Services;

import React, { useContext } from "react";
import { themeContext } from "../../Context";
import hotels from "../../img/hotel.png"
import holidays from "../../img/summer-holidays.png"
import visa from "../../img/boarding-pass.png"
import ticket from "../../img/airplane-ticket.png"
import resort from "../../img/resort.png"
import tour from "../../img/tour-bus.png"
import student from "../../img/graduated.png"
import "./Experience.css";

const Experience = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  return (
    <div className="experience" id='experience'>
      <div className="achievement">
        {/* darkMode */}
        <div className="circle" style={{color: darkMode?'var(--orange)':''}}>
        <img src={hotels} alt="image" style={{height:'60px',width:'60px'}} />

        </div>
        <span  style={{color: darkMode?'white':''}}>Hotel </span>
        <span  style={{color: darkMode?'white':''}}>Bookings </span>
      </div>
      <div className="achievement">
        {/* darkMode */}
        <div className="circle" style={{color: darkMode?'var(--orange)':''}}>
        <img src={holidays} alt="image" style={{height:'60px',width:'60px'}} />

        </div>
        <span  style={{color: darkMode?'white':''}}>Holiday </span>
        <span  style={{color: darkMode?'white':''}}>Packages </span>
      </div>
      <div className="achievement">
        {/* darkMode */}
        <div className="circle" style={{color: darkMode?'var(--orange)':''}}>
        <img src={visa} alt="image" style={{height:'60px',width:'60px'}} />

        </div>
        <span  style={{color: darkMode?'white':''}}>Visa </span>
        <span  style={{color: darkMode?'white':''}}>Assistance </span>
      </div>
      <div className="achievement">
        {/* darkMode */}
        <div className="circle" style={{color: darkMode?'var(--orange)':''}}>
        <img src={ticket} alt="image" style={{height:'60px',width:'60px'}} />

        </div>
        <span  style={{color: darkMode?'white':''}}>Air </span>
        <span  style={{color: darkMode?'white':''}}>Ticket </span>
      </div>
      <div className="achievement">
        {/* darkMode */}
        <div className="circle" style={{color: darkMode?'var(--orange)':''}}>
        <img src={resort} alt="image" style={{height:'60px',width:'60px'}} />

        </div>
        <span  style={{color: darkMode?'white':''}}>Luxury Resort </span>
        <span  style={{color: darkMode?'white':''}}>Bookings </span>
      </div>
      <div className="achievement">
        {/* darkMode */}
        <div className="circle" style={{color: darkMode?'var(--orange)':''}}>
        <img src={tour} alt="image" style={{height:'60px',width:'60px'}} />

        </div>
        <span  style={{color: darkMode?'white':''}}>Tours & </span>
        <span  style={{color: darkMode?'white':''}}>Attractions </span>
      </div>
      {/* <div className="achievement">
        <div className="circle" style={{color: darkMode?'var(--orange)':''}}>
        <img src={student} alt="image" style={{height:'60px',width:'60px'}} />

        </div>
        <span  style={{color: darkMode?'white':''}}>Student </span>
        <span  style={{color: darkMode?'white':''}}>Packages </span>
      </div> */}
      
         
    </div>
  );
};

export default Experience;

import React from "react";
import Toggle from "../Toggle/Toggle";
import "./Navbar.css";
import { Link } from "react-scroll";
import whatsapp from '../../img/whatsapp.png'

const navbar = () => {

  const phoneNumber = "+919996230894"; // Replace with your client's WhatsApp number
  const message = "Hi Travel Shoppie";

  const openWhatsApp = () => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, "_blank");
  };


  return (
    <div className="n-wrapper" id="Navbar">
      {/* left */}
      <div className="n-left">
        <div className="select-mode">Select Mode</div>
        <Toggle />
      </div>
      {/* right */}
      <div className="n-right">
        <div className="n-list">
          <ul style={{ listStyleType: "none" }}>
            <li>
              <Link activeClass="active" to="Navbar" spy={true} smooth={true}>
                Home
              </Link>
            </li>
            <li>
              <Link to="popular" spy={true} smooth={true}>
               Destination
              </Link>
            </li>
            <li>
              <Link to="services" spy={true} smooth={true}>
                Serivces
              </Link>
            </li>
        
            {/* <li>
              <Link to="portfolio" spy={true} smooth={true}>
                Protfolio
              </Link>
            </li>
          */}
             <li>
              <Link to="testimonial" spy={true} smooth={true}>
                Testimonial
              </Link>
            </li>
          </ul>
        </div>
        {/* <Link to="contact" spy={true} smooth={true}> */}
          {/* <button className="button n-button">Contact</button> */}
          <img onClick={openWhatsApp} src={whatsapp} alt="image" style={{height:'70px',width:'70px',cursor:'pointer'}} />

        {/* </Link> */}
      </div>
    </div>
  );
};

export default navbar;
